import React, { useState } from "react";
import { useStripe, useElements, IbanElement } from "@stripe/react-stripe-js";
import { useForm, Controller } from "react-hook-form";
import api from "../../services/api";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Page from "../../layouts/Page";
import { format } from "date-fns";
import { FaArrowLeft } from "react-icons/fa";

const getPlanPrice = (planId) => {
  switch (planId) {
    case 1:
      return "29.90€";
    case 2:
      return "199.90€";
    case 3:
      return "89.90€";
    default:
      return "0€";
  }
};

const PaymentForm = () => {
  const { control, handleSubmit, watch, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  const [ibanValid, setIbanValid] = useState(false);  // Nouvel état pour la validité de l'IBAN
  const { currentUser, setCurrentUser } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/plan");
  };

  const handleSubmitForm = async (data) => {
    console.log("Form submitted with data:", data);

    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded yet.");
      return;
    }

    setLoading(true);

    try {
      const authToken = localStorage.getItem("token");
      const clientSecretResponse = await api.post(
        "/subscriptions/intent",
        {},
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      const clientSecret = clientSecretResponse.data.client_secret;

      const ibanElement = elements.getElement(IbanElement);

      if (!ibanElement) {
        console.error("IbanElement is not available");
        return;
      }

      const { error, setupIntent } = await stripe.confirmSepaDebitSetup(clientSecret, {
        payment_method: {
          sepa_debit: ibanElement,
          billing_details: {
            name: data.name,
            email: currentUser.email,
          },
        },
        return_url: `${window.location.origin}/default`,
      });

      if (error) {
        console.error("Error confirming SEPA Debit setup:", error);
        throw new Error(error.message);
      }

      if (setupIntent.status === "requires_action" && setupIntent.next_action) {
        const redirectUrl = setupIntent.next_action.redirect_to_url.url;
        window.location.href = redirectUrl;
        return;
      }

      const paymentMethodId = setupIntent.payment_method;

      const response = await api.post(
        "/users/subscription",
        {
          planId: currentUser.plan_level,
          paymentMethodType: "iban",
          iban: paymentMethodId,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (response.status === 200) {
        const { subscription, user } = response.data.data;

        if (subscription.status === "requires_action") {
          console.log("Additional authentication required.");
        }

        setCurrentUser(user);
        navigate('/default');
        return "Subscription created successfully!";
      } else {
        console.error("API response error:", response.data.message);
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error("Payment processing error:", error);
      throw new Error(
        error.response?.data?.message ||
        "An error occurred while processing the payment"
      );
    } finally {
      setLoading(false);
    }
  };

  const ibanElementOptions = {
    supportedCountries: ['SEPA'],
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const nextMonthDate = format(new Date(new Date().getFullYear(), new Date().getMonth() + 2, 1), "dd/MM/yyyy");

  return (
    <Page>
      <div className="flex justify-between items-center mb-8 px-8 py-6 mt-6 bg-black rounded-xl">
        <div className="">
          <h2 className="text-3xl font-bold text-white">
            Saisissez votre moyen de paiement
          </h2>
        </div>
      </div>
      <div className="flex flex-col items-center justify-start w-full mx-auto py-16 rounded-lg bg-white relative">
        <Toaster position="top-center" reverseOrder={false} />
        <button onClick={handleGoBack} className="absolute top-4 left-4">
          <FaArrowLeft color="black" className="text-2xl" />
        </button>
        <h1 className="text-2xl font-bold mb-8">
          Entrez vos informations de paiement
        </h1>
        <form
          className="w-full max-w-sm"
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Nom du titulaire
            </label>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <input
                  {...field}
                  id="name"
                  className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name && "border-red-500"
                    }`}
                />
              )}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="iban"
            >
              IBAN
            </label>
            <div
              className={`appearance-none border rounded w-full py-2 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.iban && "border-red-500"
                }`}
            >
              <IbanElement
                options={ibanElementOptions}
                onChange={(event) => setIbanValid(event.complete)}  // Mettre à jour l'état de validité
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              className={`w-full py-2 px-4 font-semibold rounded focus:outline-none focus:shadow-outline ${loading || !watch("name") || !ibanValid
                ? "bg-gray-400 cursor-not-allowed text-white"
                : "bg-blue-500 hover:bg-blue-700 text-white cursor-pointer"
                }`}
              disabled={loading || !watch("name") || !ibanValid}  // Désactiver si loading, name ou IBAN non valide
            >
              Valider
            </button>
          </div>
          {currentUser.plan_level === 1 ? (
            <>
              <p className="font-semibold text-sm mt-3">
                L'abonnement est gratuit, le moyen de paiement est utilisé pour payer la commission des dossiers
              </p>

              <p className="font-light text-xs">
                • Résiliable en ligne à tout moment sans condition
              </p>
              <p className="font-light text-xs">
                • Chaque vente réalisée grâce à Fideliz vous sera facturée jusqu'à 39€ HT
              </p>
              <p className="font-light text-xs mt-1 italic" >Les montant de l'abonnement et des commissions peuvent varier selon les termes du contrat</p>
            </>
          ) : (
            <>
              <p className="font-semibold text-sm mt-3">
                Premier paiement de l'abonnement le {nextMonthDate}
              </p>
              {!currentUser.stripe_subscription_id && (
                <p className="font-light text-xs">
                  • Un mois d'abonnement gratuit puis{" "}
                  {getPlanPrice(currentUser.plan_level)} /mois
                </p>
              )}

              <p className="font-light text-xs">
                • Résiliable en ligne à tout moment sans condition
              </p>
              <p className="font-light text-xs">
                • Chaque vente réalisée grâce à Fideliz vous sera facturé jusqu'à 39€ HT
              </p>
              <p className="font-light text-xs mt-1 italic" >Les montant de l'abonnement et des commissions peuvent varier selon les termes du contrat</p>
            </>
          )}
        </form>
      </div>
    </Page>
  );
};

export default PaymentForm;