import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useAuth } from "../../../context/AuthContext.js";
import toast, { Toaster } from "react-hot-toast";
import Page from "../../../layouts/Page";

function AddSponsor() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const isSeller = currentUser.seller_type === 'seller'

  const onSubmit = async (data) => {
    const token = localStorage.getItem("token");
    await toast.promise(
      api.post(
        "/send-create-phone-user",
        { phone: data.phone },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      {
        loading: "Envoi en cours...",
        success: "L'invitation a bien été envoyée",
        error: (err) => {
          const errorMessage =
            err.response?.data?.message || "Une erreur est survenue.";
          return errorMessage;
        },
      }
    );
    setTimeout(() => {
      navigate("/sponsors");
    }, 2500);
  };

  // Function to handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    // Replace any non-digit character and limit to 10 digits
    e.target.value = value.replace(/\D/g, "").slice(0, 10);
  };

  return (
    <Page>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="mt-6 border-t-2 border-l-neutral-800">
        <div className="flex flex-col lg:flex-row justify-between items-center mb-8 px-4 lg:px-8 py-6 mt-6 bg-black rounded-xl">
          <div className="mb-4 lg:mb-0">
            <h2 className="text-xl font-bold text-white">
              Invitez votre futur {isSeller ? 'vendeur' : 'parraineur'}
            </h2>
            <p className="text-white font-semibold text-xs mt-2">
              Envoyez un sms avec un lien d'inscription unique !
            </p>
          </div>
          <button
            onClick={() => navigate("/sponsors")}
            className="px-6 py-3 text-white rounded-lg font-bold bg-blue-500 hover:bg-blue-600"
          >
            {isSeller ? 'Mes vendeurs' : 'Mes parraineurs'}
          </button>
        </div>
        <div className="rounded-xl pt-2 px-4 lg:px-8">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="bg-white p-6 mx-auto py-12 rounded w-full lg:w-1/3"
          >
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Numéro de mobile
              </label>
              <input
                type="tel"
                maxLength="10"
                {...register("phone", {
                  required: "Le numéro de mobile est requis",
                  pattern: {
                    value: /^0\d{9}$/,
                    message: "Le numéro doit commencer par 0 et contenir 10 chiffres",
                  },
                })}
                onInput={handleInputChange}
                className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.phone ? "border-red-500" : ""
                  }`}
              />
              {errors.phone && (
                <p className="text-red-500 text-xs italic">{errors.phone.message}</p>
              )}
            </div>

            <button
              type="submit"
              className="px-4 py-2 w-full bg-blue-500 text-white rounded hover:bg-blue-600 font-semibold"
            >
              Envoyer l'invitation
            </button>
          </form>
        </div>
      </div>
    </Page>
  );
}

export default AddSponsor;
